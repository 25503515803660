import React, { useState } from "react";
import { CustomButton } from "../index";
import "../DownloadGuide/DownloadGuide.css";
import "../Hero/Hero.css";

const ContactForm = ({ isSent, setIsSent }) => {
  // State for the form fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [interest, setInterest] = useState("all");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  // Function to trigger the guide download
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/awa-guide.pdf"; // Path to the guide
    link.download = "awa-guide.pdf"; // Filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Form submission handler
  const submit = (e) => {
    e.preventDefault();

    const webhookURL = process.env.REACT_APP_MAKE_WEBHOOK_GUIDE_URL;

    fetch(webhookURL, {
      method: "POST",
      body: JSON.stringify({ name, email, interest, isSubscribed }), // Automatically sends content-type: 'application/json'
    })
      .then(() => {
        setIsSent(true); // Set form submission state
        handleDownload(); // Trigger the download after form is submitted
      })
      .catch(() => {
        setErrorMessage("There was an error, please try again"); // Handle error
      });
  };

  return (
    <>
      {!isSent && (
        <form className='form-container' onSubmit={submit}>
          <input
            type='text'
            placeholder='Name'
            required
            onChange={(e) => setName(e.target.value)}
            name='name'
          />
          <input
            id='email'
            type='email'
            placeholder='Email'
            required
            onChange={(e) => setEmail(e.target.value)}
            name='email'
          />
          <select
            value={interest}
            onChange={(e) => setInterest(e.target.value)} // Use e.target.value here
            required
            name='interest'
          >
            <option value='' disabled>
              Please select your preference?
            </option>
            <option value='all'>All Types Of Whale Adventures</option>
            <option value='swim'>Swimming With Whales</option>
            <option value='see'>Whale Watching Adventures</option>
          </select>
          <label className='label-container'>
            <input
              type='checkbox'
              onChange={(e) => setIsSubscribed(e.target.checked)}
            />
            <span>Subscribe to our news & updates.</span>
          </label>
          <div className='button-container'>
            <CustomButton
              className='hero-btn'
              type='submit'
              text='Download Your Free Guide Now'
            />
          </div>
          {errorMessage && <p className='error'>{errorMessage}</p>}{" "}
        </form>
      )}
    </>
  );
};

export default ContactForm;
