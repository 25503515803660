import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.css"; // Import as a module

const NotFound = () => {
  return (
    <div className={styles.notFound}>
      <h2>Whoops! 404 No Whales or Pages Found Here.</h2>
      <div className={styles.whale}>🐋</div>
      <p>Looks like the page you're looking for has gone swimming away.</p>
      <Link to='/' className={styles.button}>
        Back to Shore (Home Page)
      </Link>
    </div>
  );
};

export default NotFound;
