import { useState, useEffect } from "react";

const useResponsive = (mobileBreakpoint = 768, desktopBreakpoint = 1024) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= mobileBreakpoint
  );
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth >= desktopBreakpoint
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileBreakpoint);
      setIsDesktop(window.innerWidth >= desktopBreakpoint);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mobileBreakpoint, desktopBreakpoint]);

  return { isMobile, isDesktop };
};

export default useResponsive;
