import React from "react";
import ReactPixel from "react-facebook-pixel"; // Import Facebook Pixel
import "./Button.css";

const CustomButton = ({ text, onClick }) => {
  const handleClick = () => {
    // Track the custom event using trackCustom
    ReactPixel.trackCustom("ButtonClick", {
      buttonName: text,
    });

    // Google Analytics tracking
    if (window.gtag) {
      window.gtag("event", "button_click", {
        event_category: "button",
        event_label: text,
      });
    }

    // Call the passed click handler if exists
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className='custom-button' onClick={handleClick}>
      {text}
    </button>
  );
};

export default CustomButton;
