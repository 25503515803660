import React from "react";
import "./Copyright.css";
import { useResponsive } from "../../hooks";

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  const { isMobile } = useResponsive();

  return (
    <div>
      <p className='copyright-text'>
        © {currentYear} Awesome Whale Adventures {isMobile ? <br /> : "| "}
        <a href='/privacy-policy' rel='noopener noreferrer'>
          Privacy Policy
        </a>
      </p>
    </div>
  );
};

export default Copyright;
