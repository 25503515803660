import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CustomButton } from "../index";
import "../DownloadGuide/DownloadGuide.css";
import "../Hero/Hero.css";
import "./EnquiryForm.css";

const ContactUs = () => {
  const { state } = useLocation();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Set default tour info if state is available
  const [tourInfo, setTourInfo] = useState(state || {});

  useEffect(() => {
    if (state) {
      setTourInfo(state); // Set the passed tour info when the page loads
    }
  }, [state]);

  const submit = async (e) => {
    e.preventDefault();

    // Simple form validation
    if (!fullName || !email || !message) {
      setErrorMessage(
        "Please fill out all required fields including name, email and message."
      );
      return;
    }

    setIsLoading(true); // Set loading state
    setErrorMessage(""); // Reset any previous errors

    const webhookURL = process.env.REACT_APP_MAKE_CONTACT_US_URL;

    try {
      const response = await fetch(webhookURL, {
        method: "POST",
        body: JSON.stringify({
          fullName,
          email,
          phone,
          message,
          isSubscribed,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      setIsSent(true);
      setFullName(""); // Optionally clear the fields
      setEmail("");
      setPhone("");
      setMessage("");
      setIsSubscribed(false);
    } catch (error) {
      setErrorMessage(error.message || "There was an error, please try again");
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <div className='download-guide-container'>
      <div></div>
      <div className='download-guide'>
        {!isSent ? (
          <>
            <h1>Contact Us</h1>

            {tourInfo && (
              <div className='tour-info-summary'>
                <p>{tourInfo.tourTitle}</p>
              </div>
            )}

            {tourInfo && (
              <p>
                Fill in the details below and we'll be in touch soon, or you can
                call +61 485 933 782 or email{" "}
                <a href='mailto:hello@awesomewhaleadventures.com'>
                  hello@awesomewhaleadventures.com
                </a>
              </p>
            )}

            <form className='form-container' onSubmit={submit}>
              <input
                type='text'
                placeholder='Name (required)'
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                name='full-name'
              />
              <input
                type='email'
                placeholder='Email (required)'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name='email'
              />
              <input
                type='tel'
                placeholder='Phone including country code'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name='phone'
              />
              <textarea
                placeholder='Message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name='message'
                className='message-text'
              />

              <label className='label-container'>
                <input
                  type='checkbox'
                  checked={isSubscribed}
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                />
                <span>Subscribe to our news & updates.</span>
              </label>
              <div className='button-container'>
                <CustomButton
                  className='submit-btn'
                  type='submit'
                  text={isLoading ? "Submitting..." : "Submit"}
                  disabled={isLoading} // Disable button while submitting
                />
              </div>
              {errorMessage && <p className='error'>{errorMessage}</p>}
            </form>
          </>
        ) : (
          <div className='download-guide'>
            <h1>Thank you for your message</h1>
            <h3>Our team will reach out to you within the next 24-48 hours.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
