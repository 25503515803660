import React from "react";
import Icon from "./Icon";
import "./Icons.css";

function Icons({
  hotel,
  altAccom,
  wifi,
  liveAboard,
  internalFlights,
  swim,
  research,
}) {
  return (
    <div className='icon-container'>
      <Icon iconType='hotel' show={hotel} />
      <Icon iconType='private' show={altAccom} />
      <Icon iconType='wifi' show={wifi} />
      <Icon iconType='liveAboard' show={liveAboard} />
      <Icon iconType='internalFlights' show={internalFlights} />
      <Icon iconType={swim ? "swim" : "watch"} />
      <Icon iconType='research' show={research} />
    </div>
  );
}

export default Icons;
