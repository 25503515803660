import React from "react";
import "./Hero.css";
import logo from "../../assets/images/logo-with-text.png";
import { CustomButton } from "../index";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../hooks";

const Hero = () => {
  const { isMobile } = useResponsive();

  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired route
    navigate("/all-tours");
  };
  return (
    <section className='hero'>
      <div className='hero-overlay'>
        <div className='hero-logo-container'>
          <img src={logo} className='hero-logo-icon' alt='logo icon' />
        </div>
        <div className='hero-content'>
          <h1>The world is better with whales.</h1>
          <p>
            {isMobile
              ? `Discover and book the very best whale swim and whale watching
            adventures in one place.`
              : `Discover and book the best whale swim and whale watching
            adventures here.`}
          </p>
          <div className='hero-button-container'>
            <CustomButton text='Explore All Tours' onClick={handleClick} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
