import React, { useState } from "react";
import "./TourDetails.css";
import { CustomButton, Icons } from "../index";
import { useNavigate } from "react-router-dom";
import { seeIcon, swimIcon } from "../../assets";

const TourDetails = ({ tour }) => {
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/booking-enquiry", {
      state: {
        tourID: tour.id || "unknown-id",
        tourTitle: tour.title || "",
        tourCountry: tour.country || "Country to be advised",
        tourLocation: tour.location || "Location to be advised",
        tourPrice: tour.price || 0,
        tourWhaleType: tour.whaleType || "Whale Type to be advised", // Default to a fallback whale type
        tourSwim: tour.swim || false, // Default to false if swim type is missing
      },
    });
  };

  // Function to open the gallery and set the current image index
  const openGallery = (index) => {
    setCurrentImageIndex(index); // Adjust so it starts at 0 for slice(1) images
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % (tour.images?.length - 1 || 0)
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? tour.images?.length - 2 || 0 : prevIndex - 1
    );
  };

  return (
    <div className='tour-card'>
      <img
        src={tour.images?.[0] || "🐋"}
        alt={tour.title || "Tour"}
        className='tour-image'
      />

      <div className='tour-info'>
        <div className='tour-header'>
          <div className='section-header'>
            <img
              src={tour.swim ? swimIcon : seeIcon}
              className={`symbols ${tour.swim ? "swim" : "see"}`}
              alt='tour style icon'
            />
            <div className='price-display'>
              <p className='nights'>{tour.noOfNights || "N/A"} nights</p>
              <p className='current-price'>
                {tour.from ? "From" : ""}${tour.price?.toLocaleString() || "0"}{" "}
                AUD
              </p>
              <p className='tour-price-details'>pp | twin share</p>
            </div>
          </div>
          <div className='tour-destination'>
            <h2 className='tour-country'>{tour.country || ""}</h2>
            <p className='tour-location'>{tour.location || ""}</p>
          </div>
        </div>
        <Icons
          swim={tour?.swim || false}
          hotel={tour?.hotel || false}
          liveAboard={tour?.liveAboard || false}
          internalFlights={tour?.internalFlights || false}
          research={tour?.research || false}
          wifi={tour?.wifi || false}
        />

        <div className='tour-description'>
          <p>{tour.description || "Description not yet available."}</p>
          <p>
            <strong>Your Inclusions:</strong>
          </p>
          <ul>
            {tour.inclusions?.length ? (
              tour.inclusions.map((item, index) => <li key={index}>{item}</li>)
            ) : (
              <li>No inclusions listed</li>
            )}
          </ul>
        </div>

        <div className='tour-dates-container'>
          <h4>Available Dates:</h4>
          <div className='tour-dates'>
            <p>{tour.dates || "TBA"}</p>
            {tour.limited && (
              <p className='spaces-limited'> - spaces limited</p>
            )}
          </div>
        </div>

        <div className='tour-images'>
          {/* Map through images starting from the second item (index 1) */}
          {tour.images?.slice(1)?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Tour Preview ${index + 1}`}
              className='tour-thumbnail'
              onClick={() => openGallery(index)}
            />
          ))}
        </div>

        <div className='tour-button-container'>
          <CustomButton text='Enquire Now' onClick={handleClick} />
        </div>
      </div>

      {/* Gallery Modal */}
      {isGalleryOpen && (
        <div className='gallery-modal' onClick={closeGallery}>
          <div className='gallery-content' onClick={(e) => e.stopPropagation()}>
            <button className='close-button' onClick={closeGallery}>
              &times;
            </button>
            <button className='nav-button' onClick={prevImage}>
              &lt;
            </button>
            {tour.images && (
              <img
                src={tour.images?.[currentImageIndex + 1]}
                alt='Gallery view'
                className='expanded-image'
              />
            )}
            <button className='nav-button' onClick={nextImage}>
              &gt;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TourDetails;
