import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { useLocation } from "react-router-dom";
import { PreviewCard, TourFilter } from "../components";

const AllTours = () => {
  const [tours, setTours] = useState([]);
  const [country, setCountry] = useState("");
  const [whaleType, setWhaleType] = useState("");
  const [adventureType, setAdventureType] = useState("");

  const location = useLocation();

  useEffect(() => {
    const fetchTours = async () => {
      const tourCollection = collection(db, "allTours");
      const tourSnapshot = await getDocs(tourCollection);
      const tourList = tourSnapshot.docs.map((doc) => {
        const tourData = doc.data();
        return {
          id: doc.id,
          ...tourData,
        };
      });
      setTours(tourList);
    };

    fetchTours();
  }, []);

  // Set adventureType based on the URL
  useEffect(() => {
    if (location.pathname === "/swim-tours") {
      setAdventureType("Swim");
    } else if (location.pathname === "/see-tours") {
      setAdventureType("See");
    } else {
      setAdventureType("");
    }
  }, [location]);

  // Filter tours based on country, whaleType, and adventureType
  const filteredTours = tours.filter((tour) => {
    return (
      (country === "" || tour.country === country) &&
      (whaleType === "" || tour.whaleType === whaleType) &&
      (adventureType === "" ||
        (adventureType === "Swim" ? tour.swim : !tour.swim))
    );
  });

  const formattedWhaleType = whaleType
    ? whaleType.charAt(0).toUpperCase() + whaleType.slice(1).toLowerCase()
    : "";

  return (
    <div className='all-tours-container'>
      <TourFilter
        whaleType={whaleType}
        setWhaleType={setWhaleType}
        country={country}
        setCountry={setCountry}
        adventureType={adventureType}
        setAdventureType={setAdventureType}
      />

      <div className='tour-list'>
        {filteredTours.length > 0 ? (
          filteredTours.map((tour) => {
            return <PreviewCard key={tour.id} tour={tour} />;
          })
        ) : (
          <div className='message'>
            <p>
              {`${
                formattedWhaleType ? formattedWhaleType + " whale" : "Whale"
              } ${
                adventureType === "Swim"
                  ? "swimming"
                  : adventureType === "See"
                  ? "watching"
                  : ""
              } tours are currently unavailable.`}
            </p>
            <p>
              Please note this type of tour may not be available, or future
              dates may not be scheduled yet. Please{" "}
              <a href='/contact-us'>contact us</a> for more information or check
              other options above.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllTours;
