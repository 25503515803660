import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CustomButton } from "../index";
import "../DownloadGuide/DownloadGuide.css";
import "../Hero/Hero.css";
import "./EnquiryForm.css";

const EnquiryForm = () => {
  const { state } = useLocation();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [guests, setGuests] = useState(1);
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSent, setIsSent] = useState(false);

  // Set default tour info if state is available
  const [tourInfo, setTourInfo] = useState(state || {});

  useEffect(() => {
    if (state) {
      setTourInfo(state); // Set the passed tour info when the page loads
    }
  }, [state]);

  const submit = async (e) => {
    e.preventDefault();

    const webhookURL = process.env.REACT_APP_MAKE_WEBHOOK_ENQUIRY_URL;

    try {
      const response = await fetch(webhookURL, {
        method: "POST",
        body: JSON.stringify({
          fullName,
          email,
          message,
          guests,
          phone,
          isSubscribed,
          tourInfo,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      setIsSent(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message || "There was an error, please try again");
    }
  };

  return (
    <div className='download-guide-container'>
      <div className='download-guide'>
        {!isSent ? (
          <>
            <h1>Booking Enquiry</h1>

            {tourInfo && (
              <div className='tour-info-summary'>
                <p>{tourInfo.tourTitle}</p>
              </div>
            )}

            {tourInfo && (
              <p>
                Submit your details below for the {tourInfo.tourWhaleType} whale
                tour in {tourInfo.tourCountry} and we'll be in touch with all
                the details soon!
              </p>
            )}

            <form className='form-container' onSubmit={submit}>
              <input
                type='text'
                placeholder='Name (required)'
                required
                onChange={(e) => setFullName(e.target.value)}
                name='full-name'
              />
              <input
                type='email'
                placeholder='Email (required)'
                required
                onChange={(e) => setEmail(e.target.value)}
                name='email'
              />
              <input
                type='tel'
                placeholder='Phone including country code (required)'
                required
                onChange={(e) => setPhone(e.target.value)}
                name='phone'
              />
              <textarea
                placeholder='Message'
                onChange={(e) => setMessage(e.target.value)}
                name='message'
                className='message-text'
              />

              <select
                value={guests}
                onChange={(e) => setGuests(e.target.value)}
                required
                name='guests'
              >
                <option value='' disabled>
                  How many people?
                </option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
              </select>
              <label className='label-container'>
                <input
                  type='checkbox'
                  onChange={(e) => setIsSubscribed(e.target.checked)}
                />
                <span>Subscribe to our news & updates.</span>
              </label>
              <div className='button-container'>
                <CustomButton
                  className='submit-btn'
                  type='submit'
                  text='Submit'
                />
              </div>
              {errorMessage && <p className='error'>{errorMessage}</p>}
            </form>
          </>
        ) : (
          <div className='download-guide'>
            <h1>Thank you for your enquiry!</h1>
            <h3>
              We appreciate your interest in joining our{" "}
              {tourInfo.tourWhaleType} whale tour in {tourInfo.tourCountry}.
              <br />
              <br />
              Our team will reach out to you within the next 24-48 hours with
              more details.
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnquiryForm;
