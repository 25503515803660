import React, { useState } from "react";
import "./DownloadGuide.css";
import "../Hero/Hero.css";
import ContactForm from "../Forms/ContactForm";

const DownloadGuide = () => {
  const [isSent, setIsSent] = useState(false);
  return (
    <div id='download-guide' className='download-guide-container'>
      {!isSent ? (
        <div className='download-guide'>
          <h1>Get Your Free Whale Adventure Travel Guide!</h1>
          <h3>
            PLUS exclusive updates on the best trips, destinations, and whale
            encounters worldwide.
          </h3>

          <ContactForm isSent={isSent} setIsSent={setIsSent} />
        </div>
      ) : (
        <div className='download-guide'>
          <h1>Thank you for downloading our Whale Adventure Travel Guide!</h1>

          <h3>
            Inside the guide, you'll find everything you need to start planning
            your unforgettable adventure — whether you're swimming with
            humpbacks in the South Pacific or whale watching in breathtaking
            fjords.
            <br />
            <br />
            Keep an eye on your inbox for more tips, exclusive offers, and
            updates from Awesome Whale Adventures. <br />
            <br />
            If you have any questions or need help planning your trip, feel free
            to reach out to us!
          </h3>
        </div>
      )}
    </div>
  );
};

export default DownloadGuide;
