// Social.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import "./Phone.css";

const Phone = () => {
  return (
    <div className='contact-container'>
      <div className='email-container'>
        <a
          className='email-link'
          href='mailto:hello@awesomewhaleadventures.com?subject=Website%20Enquiry'
          target='_blank'
          rel='noopener noreferrer'
        >
          hello@awesomewhaleadventures.com
        </a>
      </div>

      <div className='divider'>|</div>
      <div className='phone-container'>
        <FontAwesomeIcon icon={faPhoneVolume} />
        <p>+61 485 933 782 </p>
      </div>
    </div>
  );
};

export default Phone;
