import React, { forwardRef } from "react";
import { Social, Copyright, Phone } from "../index";
import "./Footer.css";

const Footer = forwardRef((props, ref) => {
  return (
    <div id='connect' ref={ref}>
      <div className='footer-container'>
        <Phone />
        <Social />
        <Copyright />
      </div>
    </div>
  );
});

export default Footer;
