import React from "react";
import { useNavigate } from "react-router-dom";
import { seeIcon, swimIcon } from "../../assets";
import { CustomButton, Icons } from "../index";
import "../TourDetails/TourDetails.css";

const PreviewCard = ({ tour }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Format URL parts based on tour data
    const formattedCountry =
      tour.country?.toLowerCase().replace(/\s+/g, "-") || "unknown-country";
    const formattedWhaleType =
      tour.whaleType?.toLowerCase().replace(/\s+/g, "-") || "unknown-whale";
    const adventureType = tour.swim ? "swim" : "see";
    const formattedURL = `/tours/${
      tour.id || "unknown-id"
    }/${formattedCountry}/${formattedWhaleType}/${adventureType}-adventure`;

    navigate(formattedURL);
  };

  return (
    <div className='tour-card'>
      <img
        src={tour.images?.[0] || "🐋"}
        alt={tour.title || "Tour"}
        className='tour-image'
      />

      <div className='tour-info'>
        <div className='tour-header'>
          <div className='section-header'>
            <img
              src={tour.swim ? swimIcon : seeIcon}
              className={`symbols ${tour.swim ? "swim" : "see"}`}
              alt='tour style icon'
            />

            <div className='price-display'>
              <p className='nights'>{tour.noOfNights || "N/A"} nights</p>
              <p className='current-price'>
                {tour.from ? " from " : " "}$
                {tour.price?.toLocaleString() || "0"} AUD
              </p>
              <p className='tour-price-details'>pp | twin share</p>
            </div>
          </div>

          <div className='tour-destination'>
            <h2 className='tour-country'>
              {tour.country || "Unknown Country"}
            </h2>
            <div className='tour-dates'>
              <p>{tour.dates || ""}</p>
            </div>
          </div>
        </div>
        <Icons
          swim={tour?.swim || false}
          hotel={tour?.hotel || false}
          liveAboard={tour?.liveAboard || false}
          internalFlights={tour?.internalFlights || false}
          research={tour?.research || false}
          wifi={tour?.wifi || false}
        />

        <div className='tour-description'>
          <p>{tour.intro || "Description not yet available."}</p>
        </div>

        <div className='tour-button-container'>
          <CustomButton text='More Details' onClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
