import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { TourDetails } from "../components";

const Tours = () => {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTourData = async () => {
      try {
        setLoading(true); // Set loading to true while fetching
        const tourCollection = collection(db, "allTours");
        const tourSnapshot = await getDocs(tourCollection);
        const tourList = tourSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Find the tour with the matching id from the URL
        const foundTour = tourList.find((tour) => tour.id.toString() === id);

        if (foundTour) {
          setTour(foundTour); // Set the found tour to state
        } else {
          setError("Tour not found.");
        }
      } catch (err) {
        setError("Failed to fetch tour data.");
        console.error("Error fetching tour data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTourData();
  }, [id]);

  // Conditional rendering based on loading, error, or missing tour
  if (loading) return <p className='text'>Loading tour data...</p>;
  if (error) return <p>{error}</p>;
  if (!tour) return <p>Tour not found. Please check the tour ID.</p>;

  return (
    <div className='tour-details-container'>
      <TourDetails tour={tour} />{" "}
    </div>
  );
};

export default Tours;
