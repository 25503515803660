import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import "./Blog.css";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogCollection = collection(db, "blogs");
      const blogSnapshot = await getDocs(blogCollection);
      const blogList = blogSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogs(blogList);
    };

    fetchBlogs();
  }, []);

  return (
    <div className='blog-container'>
      <h1>Our Blog</h1>
      <div className='blog-posts'>
        {blogs.map((post) => (
          <div key={post.id} className='blog-post'>
            {/* Conditionally render Hero Image only if it exists */}
            {post.heroImage && (
              <div className='hero-container'>
                <img
                  src={post.heroImage}
                  alt={post.title || ""}
                  className='blog-image'
                />
              </div>
            )}

            <h2>{post.title || ""}</h2>

            <p>
              <small>
                By {post.author || "Unknown"} on {post.date || "N/A"}
              </small>
            </p>

            <div
              className='blog-content'
              dangerouslySetInnerHTML={{
                __html: post.content || "<p>No content available</p>",
              }}
            />

            {post.tags && (
              <p>
                <strong>Tags:</strong> {post.tags.join(", ")}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
