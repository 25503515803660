import React from "react";
import { useNavigate } from "react-router-dom";
import "./TourFilter.css";

const TourFilter = ({
  whaleType,
  setWhaleType,
  adventureType,
  setAdventureType,
}) => {
  const navigate = useNavigate();

  // Handle adventure type change and update the URL
  const handleAdventureTypeChange = (e) => {
    const selectedAdventureType = e.target.value;
    setAdventureType(selectedAdventureType);

    // Update the URL based on the selected adventure type
    if (selectedAdventureType === "Swim") {
      navigate("/swim-tours");
    } else if (selectedAdventureType === "See") {
      navigate("/see-tours");
    } else {
      navigate("/all-tours");
    }
  };

  return (
    <div className='filters'>
      {/* Whale Type Filter */}
      <label>
        Whale Type:
        <select
          value={whaleType}
          onChange={(e) => setWhaleType(e.target.value)}
        >
          <option value=''>All</option>
          <option value='beluga'>Beluga</option>
          <option value='blue'>Blue</option>
          <option value='fin'>Fin</option>
          <option value='gray'>Gray</option>
          <option value='humpback'>Humpback</option>
          <option value='minke'>Minke</option>
          <option value='orca'>Orca</option>
          <option value='sperm'>Sperm</option>
          <option value='southern'>Southern Right</option>
        </select>
      </label>

      {/* Adventure Type Filter */}
      <label>
        Adventure Type:
        <select value={adventureType} onChange={handleAdventureTypeChange}>
          <option value=''>All</option>
          <option value='Swim'>Swim</option>
          <option value='See'>See</option>
        </select>
      </label>
    </div>
  );
};

export default TourFilter;
