import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./Social.css"; // Import the CSS file

const Social = () => {
  return (
    <div className='social-container'>
      <a
        className='social-link'
        href='https://wa.me/61485933782'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a
        className='social-link'
        href='mailto:hello@awesomewhaleadventures.com?subject=Website%20Enquiry'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a
        className='social-link'
        href='https://www.instagram.com/awesomewhaleadventures'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        className='social-link'
        href='https://www.facebook.com/awesomewhaleadventures'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
    </div>
  );
};

export default Social;
